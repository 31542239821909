import {AssetFieldsFragment, AssetWithSizeFieldsFragment} from '../../../../__generated__/datocms.types'
import {Asset, AssetWithSize} from '../types/asset.types'

export function parseAsset2(a: AssetFieldsFragment): Asset {
  const asset = a as Required<typeof a>
  return {
    url: asset.url,
    title: asset.title,
  }
}

export function parseAssetWithSize(a: AssetWithSizeFieldsFragment): AssetWithSize {
  const asset = a as Required<typeof a>
  return {
    ...parseAsset2(a),
    height: asset.height,
    width: asset.width,
  }
}
