import {CallToActionFieldsFragment} from '../../../../__generated__/datocms.types'
import {CallToAction, CallToActionType} from '../types/call-to-action.types'
import {ApplicationRoute, LOCALIZED_ROUTE_TOKEN} from '../../../craft/utils/application.route'
import {sanitizePathUrl} from './common.serde'

export function parseCallToAction(cta: CallToActionFieldsFragment): CallToAction {
  const item = cta as Required<typeof cta>
  const type = item.actionType as CallToActionType

  return {
    label: item.title!,
    type,
    value:
      type === 'Path'
        ? new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/${sanitizePathUrl(item.actionValue)}`)
        : item.actionValue,
    fragment: cta.anchor?.id,
  }
}
